import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Box, makeStyles } from '@material-ui/core';
import { useMobileWidgets } from '../../../helpers/hooks/useMobileWidgets';
import { UserContext } from '../../../app/Dashboard/Members/User/UserProvider';
import { styles } from '../../../app/Dashboard/ProfilePage/MedicalInfo/styles';
import { Loader } from '../../Loader';
import { Scrollbars } from '../../Scrollbars';
import { ActionsBar } from './ActionsBar';

const useStyles = makeStyles(styles);

export const MobileWidgets = ({ page, widgetsMap= {}, checkedWidgetsTypes = {}, ...props }) => {
  const {
    isOpen,
    isFetched,
    checkedWidgets,
    onReset,
    onPutItem,
    toggleItem,
    handleMinimize,
    handleMaximized
  } = useMobileWidgets({ page, checkedWidgetsTypes });
  const classes = useStyles();
  const currentUser = useSelector(({ profile }) => profile.user);
  const userContext = useContext(UserContext);
  const user = userContext ? userContext.user : currentUser;

  return (
    <div className={classes.root}>
      <Loader loading={!isFetched && !user?.medical?.id} render={
        () => (
          <Box height="100%" display="flex" flexDirection="column" overflow="hidden">
            <ActionsBar
              widgetsMap={widgetsMap}
              checkedWidgetsTypes={checkedWidgets}
              onReset={onReset}
              onToggleItem={toggleItem}
            />

            <Box flexGrow={1}>
              <Scrollbars>
                {Object.keys(checkedWidgets).map((key) => {
                  const Component = widgetsMap[key].component;

                  return checkedWidgets[key] && (
                    <Component
                      key={key}
                      isDisabledOpen
                      height={350}
                      autoHeightMax={400}
                      isCollapsed={!isOpen[key]}
                      isMinimizableType={!isOpen[key]}
                      onMaximized={handleMaximized(key)}
                      onMinimize={handleMinimize(key)}
                      onPutItem={onPutItem(key)}
                      {...props}
                    />
                  );
                })}
              </Scrollbars>
            </Box>
          </Box>
        )}
      />
    </div>
  );
};
