import { SvgIcon } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { Icon } from '@mdi/react';
import { mdiAccountMultiplePlusOutline, mdiTrashCanOutline, mdiFileMoveOutline, mdiFax } from '@mdi/js';
import { rolesMap } from '../../../dataMaps/rolesMap';
import {
  downloadFile as download,
  isPrintable,
  printFile,
  isConvertable,
  convertFile
} from '../../../helpers/files';
import { hasRole } from '../../../utils/hasRole';
import { isCurrentUserID } from '../../../utils/isCurrentUserID';
import LabelIcon from '../../../components/icons/tag-ouline.svg';
import FileEditSvg from '../../../components/icons/file-edit.svg';
import { menuOptionsTypesMap } from './menuOptionsTypes';

export const getFileMenuOptions = (context) => (file) => [
  {
    type: menuOptionsTypesMap.menuItem,
    Icon: DownloadIcon,
    label: 'Download',
    onClick: () => {
      download({ src: file?.upload_url || file?.url, name: file.original_filename });
    }
  },
  {
    isHidden: !isConvertable(file),
    type: menuOptionsTypesMap.menuItem,
    Icon: PictureAsPdfIcon,
    label: 'Download as PDF',
    onClick: () => {
      if (file.previews.pdf) {
        const name = file.original_filename.substr(0, file.original_filename.lastIndexOf('.')) + '.pdf';

        download({ src: file.previews.pdf, name });
      } else {
        convertFile(file);
      }
    }
  },
  {
    isHidden: !isPrintable(file),
    type: menuOptionsTypesMap.menuItem,
    Icon: PrintIcon,
    label: 'Print file',
    onClick: () => {
      printFile(file);
    }
  },
  {
    isHidden: context.isBillingFile,
    type: menuOptionsTypesMap.divider
  },
  {
    isHidden: !isCurrentUserID(file.creator?.id),
    type: menuOptionsTypesMap.menuItem,
    Icon: EditIcon,
    label: 'Rename',
    onClick: () => {
      context.renameFile(file);
    }
  },
  {
    isHidden: !isCurrentUserID(file.creator?.id) || !file.html_document_id,
    type: menuOptionsTypesMap.menuItem,
    Icon: (props) => (
      <SvgIcon {...props}>
        <FileEditSvg />
      </SvgIcon>
    ),
    label: 'Edit file',
    onClick: () => {
      context.editDocument(file.html_document_id);
    }
  },
  {
    isHidden: file.disk !== 'sharepoint',
    type: menuOptionsTypesMap.menuItem,
    Icon: (props) => (
      <SvgIcon {...props}>
        <FileEditSvg />
      </SvgIcon>
    ),
    label: 'Edit file',
    onClick: () => {
      context.openDocumentForEdit(file.id);
    }
  },
  {
    isHidden: !isCurrentUserID(file.creator?.id) || !hasRole(
      rolesMap.admin,
      rolesMap.supervisor,
      rolesMap.advocate,
      rolesMap.secretary
    ),
    type: menuOptionsTypesMap.menuItem,
    Icon: (props) => (
      <SvgIcon {...props}>
        <LabelIcon />
      </SvgIcon>
    ),
    label: 'Change tags',
    onClick: () => {
      context.openChangeFilesTagsModal([ file.id ]);
    }
  },
  {
    isHidden: context.isBillingFile,
    type: menuOptionsTypesMap.divider
  },
  {
    isHidden: context.isSharedFiles || context?.isBillingFile,
    type: menuOptionsTypesMap.menuItem,
    Icon: (props) => (
      <SvgIcon {...props}>
        <Icon path={mdiAccountMultiplePlusOutline} />
      </SvgIcon>
    ),
    label: 'Share',
    onClick: () => {
      context?.shareFiles?.({ filesIDs: [ file.id ] });
    }
  },
  {
    isHidden: context.isBillingFile,
    type: menuOptionsTypesMap.menuItem,
    Icon: MailOutlinedIcon,
    label: 'Share by email',
    onClick: () => {
      context.shareByEmail([ file.id ]);
    }
  },
  {
    isHidden: file.aggregate_type !== 'pdf' || context.isBillingFile,
    type: menuOptionsTypesMap.menuItem,
    Icon: (props) => <SvgIcon {...props}><Icon path={mdiFax} /></SvgIcon>,
    label: 'Send by fax',
    onClick: () => {
      context.sendByFax(file.id);
    }
  },
  {
    isHidden: context.isBillingFile,
    type: menuOptionsTypesMap.menuItem,
    Icon: FileCopyIcon,
    label: 'Make a copy',
    onClick: () => {
      context.copyFile(file);
    }
  },
  {
    isHidden: !isCurrentUserID(file.creator?.id) || context.isBillingFile,
    type: menuOptionsTypesMap.menuItem,
    Icon: (props) => (
      <SvgIcon {...props}>
        <Icon path={mdiFileMoveOutline} />
      </SvgIcon>
    ),
    label: 'Move to',
    onClick: () => {
      context.moveFile(file);
    }
  },
  {
    isHidden: !isCurrentUserID(file.creator?.id) || context.isBillingFile,
    type: menuOptionsTypesMap.divider
  },
  {
    isHidden: !isCurrentUserID(file.creator?.id),
    type: menuOptionsTypesMap.menuItem,
    Icon: (props) => (
      <SvgIcon {...props}>
        <Icon path={mdiTrashCanOutline} />
      </SvgIcon>
    ),
    label: 'Delete file',
    onClick: () => {
      context.deleteFiles([ file.id ]);
    }
  }
];
