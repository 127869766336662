import { useMediaQuery, useTheme } from '@material-ui/core';
import { MobileWidgets } from '../../../../../../../components/Widgets/MobileWidgets';
import * as pages from '../../../../../../../store/dashboard/layout/pages';
import { checkedWidgetsTypes } from './Layouts';
import { widgetsMap } from './Widgets';
import { MainInfo } from './MainInfo';

export const MedicalProfile = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));

  return isTablet ? (
    <MobileWidgets
      page={pages.medicalUserProfile}
      widgetsMap={widgetsMap}
      checkedWidgetsTypes={checkedWidgetsTypes}
    />
  ) : <MainInfo />;
};

