import { useState } from 'react';
import { Box, Dialog, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ModalBody, ModalContainer, ModalHeader } from '../../../Modal';
import { UserLink } from '../../../UserLink';
import { MobileTracks } from '../List/Row/MobileTracks';
import { Tracks } from '../List/Row/Tracks';
import { TableHeader } from './TableHeader';

export const WorkingSessionInfoModal = ({
  DialogProps,
  handleModalReject,
  payload: { workSession, deleteTrack = () => {} }
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [ tracks, setTracks ] = useState(workSession.session_tracks);

  const onTrackDelete = (trackId) => () => {
    const cb = () => setTracks(tracks.filter((track) => track.id !== trackId));

    deleteTrack(workSession.id, trackId, cb);
  };

  return (
    <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
      <ModalContainer p={0} >
        <ModalHeader onClose={handleModalReject}>
          <Box display="flex" alignItems="baseline">
            <Typography variant="h4">Work Session</Typography>

            &nbsp;

            <UserLink
              noAvatar
              size="md"
              variant="h5"
              user={workSession.user}
            />
          </Box>
        </ModalHeader>

        <ModalBody disablePaddings={!isMobile}>
          {!isMobile && <TableHeader />}

          {isMobile ?
            <MobileTracks tracks={tracks} onTrackDelete={onTrackDelete} /> :
            <Tracks tracks={tracks} onTrackDelete={onTrackDelete} />
          }
        </ModalBody>
      </ModalContainer>
    </Dialog>
  );
};
