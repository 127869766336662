import { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Button, Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import { api } from '../../../../../api';
import * as templatesApi from '../../../../../api/profile';
import { Loader } from '../../../../../components/Loader';
import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader
} from '../../../../../components/Modal';
import { REFILL_OPTIONS } from '../../Settings/PrescriptionsTemplates/CreateTemplateModal';
import { Body, validationSchema } from '../CreateModal';

export const EditModal = ({
  DialogProps,
  handleModalResolve,
  handleModalReject,
  payload: { id }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const cancelFetch = useRef(() => {});
  const [ initialValues, setInitialValues ] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const createPrescription = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return templatesApi.updatePrescription(values).then(({ data }) => {
      enqueueSnackbar('Successfully created', { variant: 'success' });
      handleModalResolve(data);
    }).catch(({ errors }) => {
      enqueueSnackbar('Not created', { variant: 'error' });
      setErrors(errors);
    });
  };

  useEffect(() => {
    templatesApi.fetchPrescription(id, {
      cancelToken: new api.CancelToken((cancel) => (cancelFetch.current = cancel))
    }).then(setInitialValues).catch(() => {
      enqueueSnackbar('Something goes wrong', { variant: 'error' });
    });

    return () => {
      cancelFetch.current();
    };
  }, []);

  return (
    <Dialog fullScreen={isMobile} maxWidth="md" {...DialogProps}>
      <Loader p={3} loading={!initialValues} render={
        () => (
          <Formik
            initialValues={{
              ...initialValues,

              patient_id: initialValues.patient,
              provider_id: initialValues.provider,
              office_id: initialValues.office,
              icd_code_ids: initialValues?.icd_codes,
              options: {
                generic_equivalent_acceptable: (
                  initialValues?.options?.generic_equivalent_acceptable || 'no'
                ),
                refill: initialValues?.options?.refill || REFILL_OPTIONS
              }
            }}
            validationSchema={validationSchema}
            onSubmit={createPrescription}
          >
            {({ handleSubmit, isSubmitting }) => (
              <ModalContainer noValidate component="form" onSubmit={handleSubmit}>
                <ModalHeader onClose={handleModalReject}>
                  Edit Prescription
                </ModalHeader>

                <ModalBody>
                  <Body />
                </ModalBody>

                <ModalFooter>
                  <Button onClick={handleModalReject}>Cancel</Button>

                  <Loader surface loading={isSubmitting} render={
                    () => (
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    )}
                  />
                </ModalFooter>
              </ModalContainer>
            )}
          </Formik>
        )}
      />
    </Dialog>
  );
};
