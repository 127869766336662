import { AllSchedules } from '../../../../../../ProfilePage/GeneralInfo/Widgets/AllSchedules';
import { ClaimDetails } from '../../../../../../ProfilePage/MedicalInfo/MainInfo/Widgets/ClaimDetails';
import { InsuranceDetails } from '../../../../../../ProfilePage/MedicalInfo/MainInfo/Widgets/InsuranceDetails';
import { NextAppointment } from '../../../../../../ProfilePage/MedicalInfo/MainInfo/Widgets/NextAppointment';
import * as cardsTypes from '../Layouts';
// import { NextAppointment } from './NextAppointment';
import { MedicalHistory } from './MedicalHistory';
import { MedicalReport } from './MedicalReport';
import { PatientPacket } from './PatientPacket';
// import { ClaimDetails } from './ClaimDetails';
import { Comments } from './Comments';

export const widgetsMap = {
  [cardsTypes.COMMENTS]: {
    title: 'Comments',
    component: Comments
  },

  [cardsTypes.NEXT_APPOINTMENT]: {
    title: 'Next Appointment',
    component: NextAppointment
  },

  [cardsTypes.ALL_SCHEDULES]: {
    title: 'All Schedules',
    component: AllSchedules
  },

  [cardsTypes.INSURANCE_DETAILES]: {
    title: 'Insurance Details',
    component: InsuranceDetails
  },

  [cardsTypes.PATIENT_PACKET]: {
    title: 'Patient Packet',
    component: PatientPacket
  },

  [cardsTypes.MEDICAL_REPORT]: {
    title: 'Medical Report',
    component: MedicalReport
  },

  [cardsTypes.MEDICAL_HISTORY]: {
    title: 'Medical History',
    component: MedicalHistory
  },

  [cardsTypes.CLAIM_DITAILES]: {
    title: 'Claim Details',
    component: ClaimDetails
  }
};
