import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import * as pages from '../../../../../../../store/dashboard/layout/pages';
import { Widgets } from '../../../../../../../components/Widgets';
import { Loader } from '../../../../../../../components/Loader';
import { UserContext } from '../../../UserProvider';
import { cardsLayout, checkedWidgetsTypes, extraTypes } from './Layouts';
import { widgetsMap } from './Widgets';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const MainInfo = () => {
  const classes = useStyles();
  const profile = useSelector(({ profile }) => profile);
  const userContext = useContext(UserContext);
  const user = userContext ? userContext.user : profile.user;

  return (
    <div className={classes.root}>
      <Loader loading={!user?.medical?.id} render={
        () => (
          <Widgets
            rowHeight={28}
            page={pages.medicalUserProfile}
            cardsLayout={cardsLayout}
            extraTypes={extraTypes}
            widgetsMap={widgetsMap}
            checkedWidgetsTypes={checkedWidgetsTypes}
          />
        )}
      />
    </div>
  );
};
