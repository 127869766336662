import moment from 'moment';
import cn from 'classnames';
import { makeStyles, Box, Typography, useMediaQuery } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import AlarmOnOutlinedIcon from '@material-ui/icons/AlarmOnOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import Icon from '@mdi/react';
import { mdiWrenchClock } from '@mdi/js';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { trackTypesMap } from '../../../../FormField';
import { ListRow, ListRowCell } from '../../../../ListRow';
import { IconButton } from '../../../../IconButton';
import { Date } from '../../../../Date';
import { useModal } from '../../../../ModalsProvider';
import { SvgIcon } from '../../../../SvgIcon';
import { Tooltip } from '../../../../Tooltip';
import { EditWorkClocksModal } from '../../../EditWorkClocksModal';
import { tracksTypesLabelsMap } from '../../../trackTypesMap';

export const columnsWidths = {
  icon: 40,
  type: 130,
  start: 165,
  finish: 165,
  duration: 220,
  action: 90
};

const useStyles = makeStyles(({ palette }) => ({
  track: {
    background: lighten(palette.warning.main, 0.85)
  }
}));

export const Tracks = ({ tracks, onTrackUpdate = () => {}, onTrackDelete = () => {} }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const openTrackEditModal = (id) => () => {
    openModal(EditWorkClocksModal, {
      payload: {
        trackID: id
      },

      onModalResolved: (track) => {
        onTrackUpdate(track);
      }
    });
  };

  return (
    <>
      <ListRow header px={2}>
        <ListRowCell flexBasis={columnsWidths.icon} />
        <ListRowCell grow flexBasis={columnsWidths.type}>Type</ListRowCell>
        <ListRowCell flexBasis={columnsWidths.start}>Start</ListRowCell>
        <ListRowCell flexBasis={columnsWidths.finish}>End</ListRowCell>
        <ListRowCell flexBasis={columnsWidths.duration}>Duration</ListRowCell>
        <ListRowCell flexBasis={columnsWidths.action} />
      </ListRow>

      {tracks?.map((track) => {
        const isManuallyChanged = track.created_by_id || track.changed_id;
        const breakOvertime = track.finished_at - track.started_at - 10 * 60;

        return (
          <ListRow key={track.id} px={2} className={cn({ [classes.track]: isManuallyChanged })}>
            <ListRowCell flexBasis={columnsWidths.icon}>
              <Tooltip title={isManuallyChanged ? 'Track added manually' : 'Track added from work clock'}>
                {isManuallyChanged ? (
                  <SvgIcon><Icon path={mdiWrenchClock} /></SvgIcon>
                ) : (
                  <AlarmOnOutlinedIcon />
                )}
              </Tooltip>
            </ListRowCell>

            <ListRowCell grow column flexBasis={columnsWidths.type}>
              <Typography variant="h5">
                {tracksTypesLabelsMap[track.type]}
              </Typography>

              <Typography noWrap>
                {track.note || ''}
              </Typography>
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.start}>
              <Date disableIcon date={track.started_at} format="L LT" />
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.finish}>
              <Date disableIcon date={track.finished_at} format="L LT" />
            </ListRowCell>

            <ListRowCell flexBasis={columnsWidths.duration}>
              {!track.finished_at ? '-' : moment.duration(
                track.finished_at - track.started_at,
                'seconds'
              ).format('h[h] m[min]')}

              {(track.type === trackTypesMap.break) && (breakOvertime > 0) && (
                <>
                  &nbsp;/&nbsp;

                  <Box color="error.main" component="span">
                    {moment.duration(breakOvertime, 'seconds').format('h[h] m[min]')}
                  </Box>
                </>
              )}
            </ListRowCell>

            {hasRole(rolesMap.admin, rolesMap.supervisor) && (
              <ListRowCell
                pr={2}
                justifyContent="flex-end"
                flexBasis={columnsWidths.action}
              >
                <IconButton
                  size={isTablet ? 'small' : 'medium'}
                  color="primary"
                  variant="contained"
                  onClick={openTrackEditModal(track.id)}
                >
                  <EditIcon />
                </IconButton>

                <IconButton
                  edge="end"
                  title="Delete"
                  color="error"
                  onClick={onTrackDelete(track.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListRowCell>
            )}
          </ListRow>
        );
      })}
    </>
  );
};
