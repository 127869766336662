import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Box, Grid, SvgIcon, Typography } from '@material-ui/core';
import { mdiDraw } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as templatesApi from '../../../../../api/profile';
import { rolesMap } from '../../../../../dataMaps/rolesMap';
import { hasRole } from '../../../../../utils/hasRole';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { ReadonlyCheckbox } from '../../../../../components/ReadonlyCheckbox';
import { SignatureImage } from '../../../../../components/UserSignature';
import { useModal } from '../../../../../components/ModalsProvider';
import { UserLink } from '../../../../../components/UserLink';
import { Button } from '../../../../../components/Button';
import { Date } from '../../../../../components/Date';

export const Body = ({ prescription }) => {
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector(({ profile }) => profile.user);
  const [ signature, setSignature ] = useState(prescription?.signature || null);

  const handleSignForm = () => {
    if (!currentUser.signature) {
      enqueueSnackbar('You don\'t have a signature yet', { variant: 'warning' });

      return;
    }

    openModal(ConfirmationModal, {
      onModalResolved: () => {
        const signValue = {
          ...prescription,
          signature: currentUser?.signature || null,
          signature_id: currentUser?.signature?.id || null
        };

        templatesApi.updatePrescription(signValue).then(({ data }) => {
          setSignature(data.signature);
        }).catch(() => {
          enqueueSnackbar('Not signed', { variant: 'error' });
        });
      }
    });
  };

  return (
    <Grid container spacing={2} alignItems="center" component={Box} pb={4}>
      <Grid item md={2} sm={6} xs={6}>
        <Typography variant="button">Name</Typography>
      </Grid>

      <Grid item md={4} sm={6} xs={6}>
        <UserLink user={prescription.patient} />
      </Grid>

      <Grid item md={2} sm={6} xs={6}>
        <Typography variant="button">Date</Typography>
      </Grid>

      <Grid item md={4} sm={6} xs={6}>
        <Date disableMoment date={moment(prescription.date).format('L')} />
      </Grid>

      <Grid item sm={12}>
        <Typography variant="button">Description:</Typography>
      </Grid>

      <Grid item sm={12}>
        <Typography>{prescription.text}</Typography>
      </Grid>

      <Grid item sm={12}>
        <Typography variant="button">DX:</Typography>
      </Grid>

      {!prescription.icd_codes?.length ? (
        <Grid item sm={12}>
          <Typography color="textSecondary">No info</Typography>
        </Grid>
      ) : (prescription.icd_codes?.map((icd) => (
        <Grid item xs={12} key={icd.id}>
          <Box display="flex" alignItems="center">
            <Box clone minWidth={100} mr={2}>
              <Typography color="primary" variant="h5">{icd.code}</Typography>
            </Box>

            <Typography>{icd.description}</Typography>
          </Box>
        </Grid>
      )))}

      <Grid item sm={12} />

      <Grid item sm={12} xs={12}>
        <Typography gutterBottom variant="body2">
          License No: {prescription?.provider?.lic || '-'}
        </Typography>

        <Typography gutterBottom variant="body2">
          Refill: {prescription?.options?.refill || '-'}
        </Typography>

        <Box display="flex" alignItems="center">
          <Typography variant="body2">Generic Equivalent Acceptable:</Typography>
          &nbsp;&nbsp;
          <ReadonlyCheckbox
            variant="body2"
            checked={prescription?.options?.generic_equivalent_acceptable === 'yes'}
          />
        </Box>
      </Grid>

      <Grid item md={2} sm={12} xs={12}>
        <Typography variant="button">Signature:</Typography>
      </Grid>

      {!!signature ? (
        <>
          <Grid item sm={4} xs={12}>
            <SignatureImage url={signature?.electronic_signature_file || signature?.url} />
          </Grid>

          {!!signature?.user ? (
            <Grid item md={6} sm={8} xs={12}>
              <UserLink
                variant="h5"
                user={signature?.user}
              >
                <Date color="textSecondary" variant="caption" date={signature?.signed_on} />
              </UserLink>
            </Grid>
          ) : (
            <Date color="textSecondary" variant="caption" date={signature?.signed_on} />
          )}
        </>
      ) : (
        <Grid>
          <Button
            disabled={!hasRole(rolesMap.doctor, rolesMap.medic)}
            size="small"
            color="primary"
            startIcon={<SvgIcon><Icon path={mdiDraw} /></SvgIcon>}
            onClick={handleSignForm}
          >
            Sign
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
