import {
  fetchUserStorageError,
  fetchUserStorageRequest,
  fetchUserStorageSuccess,
  updateUserStorageSuccess
} from './actions';
import * as usersApi from '../../api/users';

export const fetchUserStorage = () => (dispatch) => {
  dispatch(fetchUserStorageRequest());

  usersApi.fetchUserStorage().then((data) => {
    dispatch(fetchUserStorageSuccess(data));
  }).catch(({ status, ...error }) => {
    if (status === 404) {
      dispatch(fetchUserStorageError());
    } else {
      throw error.data;
    }
  });
};

export const updateUserStorage = (data) => (dispatch, getState) => {
  const globalUserData = getState()?.globalUser?.data;

  dispatch(updateUserStorageSuccess(data));

  return usersApi.updateUserStorage({ ...globalUserData, ...data });
};

export const addHiddenFilter = (filterKey, newField) => (dispatch, getState) => {
  const globalUserData = getState()?.globalUser?.data;
  const data = {
    ...globalUserData,

    hiddenFilterFields: {
      ...globalUserData.hiddenFilterFields,

      [filterKey]: (globalUserData.hiddenFilterFields?.[filterKey] || [])?.concat(newField)
    }
  };

  dispatch(updateUserStorageSuccess(data));

  return usersApi.updateUserStorage(data).catch((error) => {
    dispatch(updateUserStorageSuccess(globalUserData));

    throw error.data;
  });
};

export const removeHiddenFilter = (filterKey, removedField) => (dispatch, getState) => {
  const globalUserData = getState()?.globalUser?.data;
  const data = {
    ...globalUserData,

    hiddenFilterFields: {
      ...globalUserData.hiddenFilterFields,

      [filterKey]: globalUserData.hiddenFilterFields?.[filterKey]?.filter((field) => removedField !== field)
    }
  };

  dispatch(updateUserStorageSuccess(data));

  return usersApi.updateUserStorage(data).catch((error) => {
    dispatch(updateUserStorageSuccess(globalUserData));

    throw error.data;
  });
};
