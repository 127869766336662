import { useState, useEffect, useRef } from 'react';
import { merge } from 'lodash';
import { makeStyles, Popper as MuiPopper, ClickAwayListener, Fade, Paper } from '@material-ui/core';
import { generateUID } from '../../helpers/generateUID';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const componentUID = generateUID();

export const Popper = ({
  isOpen: isOpenProp,
  anchorEl: anchorElProp = null,
  placement = 'bottom',
  arrow = true,
  anchorRenderer,
  children,
  className,
  modifiers = {},
  classes: classesProp,
  onClosed = () => {},

  ...props
}) => {
  const classes = useStyles({ classes: classesProp });
  const [ isOpen, setIsOpen ] = useState(false);
  const [ arrowEl, setArrowEl ] = useState(null);
  const anchorRef = useRef(null);
  const anchorEl = anchorElProp || anchorRef.current;

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
    onClosed();
  };

  const handleClickAway = ({ target }) => {
    if (
      !anchorEl?.contains?.(target) &&
      !target.closest(`[component-uid="${componentUID}"]`) &&
      !document.body.querySelector('.MuiDialog-root')
    ) {
      handleClose();
    }
  };

  const handleClickOnPopper = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (isOpenProp) {
      setIsOpen(isOpenProp);
    }
  }, [ isOpenProp ]);

  return (
    <>
      {anchorRenderer?.({ isOpen, anchorRef, handleToggle })}

      <MuiPopper
        transition
        onClick={handleClickOnPopper}
        className={classes.popper}
        open={isOpen}
        anchorEl={anchorEl}
        placement={placement}
        component-uid={componentUID}
        modifiers={merge({
          flip: {
            enabled: true
          },
          arrow: {
            enabled: arrow,
            element: arrowEl
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport'
          }
        }, modifiers)}

        {...props}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <div>
              <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                  {!!arrow && <div className={classes.triangle} ref={setArrowEl} />}

                  <Paper className={classes.popperContent}>
                    {typeof children === 'object' && children }
                    {typeof children === 'function' && children({ handleClose }) }
                  </Paper>
                </div>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </MuiPopper>
    </>
  );
};
