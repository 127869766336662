import { useContext } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Typography, makeStyles, Box, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import * as workSessionsApi from '../../../../../api/work-clocks';
import { stopPropagation } from '../../../../../helpers/dom';
import { workClockTypesMap } from '../../../../../store/dashboard/workClock/workClockTypesMap';
import { WorkSessionsContext } from '../../../WorkSessionsProvider';
import { ConfirmationModal } from '../../../../ConfirmationModal';
import { VerticalDivider } from '../../../../VerticalDivider';
import { useModal } from '../../../../ModalsProvider';
import { UserPreviewModal } from '../../../../users';
import { IconButton } from '../../../../IconButton';
import { UserLink } from '../../../../UserLink';
import { Tooltip } from '../../../../Tooltip';
import { Divider } from '../../../../Divider';
import { CardItem } from '../../../../Cards';
import { Date } from '../../../../Date';
import { useLiveSessionDuration } from '../../useLiveSessionDuration';
import { WorkingSessionInfoModal } from '../../WorkingSessionInfoModal';
import { styles } from './styles';

const useStyles = makeStyles(styles);

const TracksTooltip = ({ tracks }) => {
  return tracks.map((track) => (
    <Typography key={track.id} color="inherit">
      {moment.unix(track.started_at).format('LT')} - {!!track.finished_at && (
        moment.unix(track.finished_at).format('LT')
      )}
    </Typography>
  ));
};

export const Card = ({ item: workSession }) => {
  const classes = useStyles();
  const { openModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const workSessionsContext = useContext(WorkSessionsContext);
  const liveDuration = useLiveSessionDuration(workSession.session_tracks, workSession.active_track);

  const openUserPreview = () => {
    openModal(UserPreviewModal, {
      payload: { id: workSession?.user?.id }
    });
  };

  const openWorkingSessionInfoModal = () => {
    openModal(WorkingSessionInfoModal, {
      payload: { workSession, deleteTrack: workSessionsContext.deleteTrack }
    });
  };

  const getTracksByType = (type) => {
    return workSession.session_tracks.filter((track) => track.type === type);
  };

  const deleteWorkSession = () => {
    openModal(ConfirmationModal, {
      onModalResolved: () => {
        workSessionsApi.deleteWorkSession(workSession.id).then(() => {
          workSessionsContext.deleteWorkSession(workSession);
          enqueueSnackbar('Work session deleted', { variant: 'success' });
        }).catch(() => {
          enqueueSnackbar('Work session not deleted', { variant: 'error' });
        });
      }
    });
  };

  return (
    <CardItem onClick={openWorkingSessionInfoModal}>
      <div className={classes.body}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography>User:</Typography>
          </Grid>

          <Grid item xs={8}>
            <UserLink
              size="sm"
              variant="h5"
              user={workSession.user}
            />
          </Grid>

          <Grid item xs={4}>
            <Typography>Start:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Date disableIcon date={workSession.started_at} format="L LT" />
          </Grid>

          <Grid item xs={4}>
            <Typography>End:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Date disableIcon date={workSession.finished_at} format="L LT" />
          </Grid>

          <Grid item xs={4}>
            <Typography>Duration:</Typography>
          </Grid>

          <Grid item xs={8}>
            {workSession.finished_at ? (
              <Tooltip
                isExistTooltip={getTracksByType(workClockTypesMap.work).length}
                title={<TracksTooltip tracks={getTracksByType(workClockTypesMap.work)} />}
              >
                <Typography>
                  {moment.duration(workSession.time_info.work, 'seconds').format('h[h] m[min]')}
                </Typography>
              </Tooltip>
            ) : (
              <Box color="success.main">
                {liveDuration}
              </Box>
            )}
          </Grid>

          <Grid item xs={4}>
            <Typography>Breaks:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Tooltip
              isExistTooltip={getTracksByType(workClockTypesMap.break).length}
              title={<TracksTooltip tracks={getTracksByType(workClockTypesMap.break)} />}
            >
              <Typography>
                {moment.duration(workSession.time_info.break, 'seconds').format('h[h] m[min]')}
              </Typography>
            </Tooltip>
          </Grid>

          <Grid item xs={4}>
            <Typography>Lunches:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Tooltip
              isExistTooltip={getTracksByType(workClockTypesMap.lunch).length}
              title={<TracksTooltip tracks={getTracksByType(workClockTypesMap.lunch)} />}
            >
              <Typography>
                {moment.duration(workSession.time_info.lunch, 'seconds').format('h[h] m[min]')}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </div>

      <Divider />

      <Box display="flex" alignItems="center" alignContent="center" height={46}>
        <Box width="50%" display="flex" justifyContent="center">
          <IconButton
            color="primary"
            title="More info"
            onClick={stopPropagation(openUserPreview)}
          >
            <InfoOutlinedIcon />
          </IconButton>
        </Box>

        <VerticalDivider />

        <Box width="50%" display="flex" justifyContent="center">
          <IconButton
            color="error"
            title="Delete session"
            onClick={stopPropagation(deleteWorkSession)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </CardItem>
  );
};
