import { Formik } from 'formik';
import { Button, Dialog } from '@material-ui/core';
import AddAlertOutlinedIcon from '@material-ui/icons/AddAlertOutlined';
import { useSnackbar } from 'notistack';
import * as remindersApi from '../../../../../api/cases/reminders/case-reminders';
import { Yup } from '../../../../../utils/validation';
import { ModalFooter, ModalHeader, ModalBody } from '../../../../Modal';
import { Loader } from '../../../../Loader';
import { CasesSelect } from '../../../CasesSelect';
import { CaseReminderGroupsSelect } from '../../groups';

const initialValues = {
  group_ids: null
};

const validationSchema = Yup.object().shape({
  case_id: Yup.mixed().nullable().required(),
  group_ids: Yup.mixed().nullable().required()
});

export const CreateCaseRemindersModal = ({
  payload: {
    caseId
  },
  DialogProps,
  handleModalResolve,
  handleModalReject
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const addReminderEvent = (values, { isSubmitting, setErrors }) => {
    if (isSubmitting) {
      return;
    }

    return remindersApi.createCaseReminders(values).then((reminders) => {
      enqueueSnackbar('Reminders successfully created', { variant: 'success' });
      handleModalResolve(reminders);
    }).catch(({ data: { errors } }) => {
      if (errors) {
        setErrors(errors);
      }

      enqueueSnackbar('Reminders not created', { variant: 'error' });
    });
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,

        case_id: caseId
      }}
      validationSchema={validationSchema}
      onSubmit={addReminderEvent}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Dialog maxWidth="sm" {...DialogProps}>
          <form noValidate onSubmit={handleSubmit}>
            <ModalHeader
              icon={<AddAlertOutlinedIcon />}
              onClose={handleModalReject}
            >
              Create reminders
            </ModalHeader>

            <ModalBody>
              {!caseId &&
                <CasesSelect
                  required
                  label="Case"
                  name="case_id"
                  margin="dense"
                />
              }

              <CaseReminderGroupsSelect
                required
                multiple
                name="group_ids"
                label="Group"
                margin="dense"
              />
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleModalReject}>Cancel</Button>

              <Loader surface loading={isSubmitting} render={
                () => (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save
                  </Button>
                )}
              />
            </ModalFooter>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};
